import { navigate } from "gatsby";
import * as linksEN from "./src/locales/en/links.json";
import * as linksPL from "./src/locales/pl/links.json";
import { getCurrentLang } from "./src/utils/getCurrentLang";

const links = {
  en: linksEN,
  pl: linksPL,
};

export const onInitialClientRender = () => {
  const selectedPreferredLang = localStorage.getItem("preferred_lang");

  if (sessionStorage.getItem("redirected") && !selectedPreferredLang) {
    return;
  }

  const userLang = navigator.language || navigator.userLanguage;
  const pathname = window.location.pathname;
  const currentLang = getCurrentLang(pathname);
  const preferredLang = selectedPreferredLang ?? userLang.split("-")[0];
  const keyLang = Object.entries(links[currentLang]).find(
    ([_, value]) => value === pathname
  )?.[0];

  if (preferredLang !== currentLang) {
    sessionStorage.setItem("redirected", "true");

    if (links[preferredLang][keyLang]) {
      navigate(links[preferredLang][keyLang], { replace: true });
    }
  }
};
